import {
    authHeader
} from '../helpers';

export const greetingsService = {
    getData,
    deleteExisting,
    accept,
    decline
};

const route = '/api/Greetings';

function getData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    return fetch(process.env.VUE_APP_API_ROUTE + route + '/GetAllGreetings', requestOptions).then(handleResponse);
}

function accept(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    
    return fetch(process.env.VUE_APP_API_ROUTE + route + '/Accept', requestOptions).then(handleResponse);
}

function decline(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(id)
    };
    
    return fetch(process.env.VUE_APP_API_ROUTE + route + '/Decline', requestOptions).then(handleResponse);
}


function deleteExisting(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/' + payload.id, requestOptions).then(handleResponse);
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('vue-token');
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

import {
    authHeader,
    authHeaderUndefined
} from '../helpers';

export const imageService = {
    getAll,
    newImage,
    deleteImage,
    getImage,
};

function getImage(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/api/Photos/` + id, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/api/Photos/`, requestOptions).then(handleResponse);
}

function newImage(image) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderUndefined(),
        body: image
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/api/Photos`, requestOptions).then(handleResponse);
}


function deleteImage(image) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(image)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + `/api/Photos/` + image.ID, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('vue-token');
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
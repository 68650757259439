<template>
  <div>
        <v-container fluid>
      <v-row>
        <v-col>
         <router-link to="/">Zurück</router-link>
         </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="9">
          <h2>{{ Gallery.title }}</h2>
          <span
            >In den einzelnen Galerien haben Sie die Möglichkeit Bilder hoch und
            herunterzuladen.</span
          >
        </v-col>
        <v-col cols="3">
          <!--<v-btn @click="downloadGallery()" color="cyan" class="mr-1"
            >Alle herunterladen</v-btn
          >-->

        <v-btn @click="downloadSelection()" color="primary" class="mr-1" v-if="addedImages.length > 0"
            >Auswahl herunterladen</v-btn
          >


          <!--<template v-if="addedImages.length > 0">
            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn tile color="primary" v-bind="attrs" v-on="on"
                  >Bilderauswahl zusenden</v-btn
                >
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark
                    >Auswahl per Mail zusenden</v-toolbar
                  >
                  <v-card-text>
                    <v-form v-model="valid" class="mx-auto">
                      <v-text-field
                        v-model="email"
                        :rules="inputRules"
                        label="Ihre E-Mail Adresse"
                        required
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Schließen</v-btn>
                    <v-btn tile color="primary" @click="Save"
                      >Auszwahl zusenden</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>-->
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="9">
          <v-row v-if="Gallery.photos">
            <v-col v-for="list in Gallery.photos" :key="list.id">
              <v-card max-width="240" max-height="240" class="hoverImage">
                <template v-if="parseUrl(list.url)">
                  <v-img
                    class="white--text align-end"
                    :lazy-src="parseUrl(list.thumbnailUrl)"
                    :src="parseUrl(list.thumbnailUrl)"
                    min-height="240"
                    min-width="240"
                    max-width="240"
                    max-height="240"
                  >
                    <v-card-actions
                      style="right: 0; top: 0; position: absolute"
                    >
                      <v-btn @click="showConfirmBar(list)" color="error" v-if="isAdmin"
                        ><i class="fa fa-trash-alt"></i
                      ></v-btn>
                      <v-btn @click="openImage(list.url)" color="white"
                        ><i class="fa fa-eye"></i>
                      </v-btn>
                      <v-btn
                        @click="handleImage(list.id)"
                        color="green"
                        style="color: white"
                        v-if="isClicked(list.id)"
                      >
                        <i class="fa fa-check"></i>
                      </v-btn>
                      <v-btn
                        @click="handleImage(list.id)"
                        color="white"
                        style="color: black"
                        v-else
                      >
                        <i class="fa fa-plus"></i>
                      </v-btn>
                    </v-card-actions>
                    <v-card-title>{{ list.title }}</v-card-title>
                    <v-card-subtitle class="pb-0"
                      >{{ list.description }}
                    </v-card-subtitle>
                  </v-img>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-card elevation="2">
            <v-card-title>Neue Bilder hochladen</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <div class="pt-0">
                <v-form
                  @submit.prevent="newImage"
                  enctype="multipart/form-data"
                  id="upload-form"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp"
                        name="file"
                        v-model="file"
                        label="Datei Upload"
                        filled
                        prepend-icon="mdi-camera"
                        multiple="multiple"
                      ></v-file-input>

                      <div v-show="submitted && !file" class="invalid-feedback">
                        Bitte Datei auswählen
                      </div>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            type="submit"
                            form="upload-form"
                            color="primary"
                            :disabled="submitted || !file"
                          >
                            Hochladen...
                          </v-btn>
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="submitted"
                          ></v-progress-circular>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <ul style="list-style: none">
                            <li
                              v-for="s in uploadSuccesses"
                              :key="s.item"
                              class="successfull"
                            >
                              Erfolgreich: {{ s }}
                            </li>
                            <li
                              v-for="s in uploadErrors"
                              :key="s.item"
                              class="failed"
                            >
                              Fehlgeschlagen: {{ s }}
                            </li>
                          </ul>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center ma-2" v-if="isAdmin">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie das Bild löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-overlay :value="submitted">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<style scoped>
.successfull {
  background-color: rgb(167, 218, 167);
  list-style: none;
  color: black;
  margin-left: -25px;
  padding: 6px;
  border: thin lightgray solid;
}
.failed {
  background-color: rgb(252, 136, 136);
  color: white;
  list-style: none;
  margin-left: -25px;
  padding: 6px;
  border: thin lightgray solid;
}
.hoverImage:hover {
  box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
}
</style>

<script>
import moment from "moment";

export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_gallery_details", () => {
      this.getData();
    });
    this.$root.$on("reload_galleries_data", () => {
      this.reload();
    });
  },
  data() {
    return {
      dialogVisible: false,
      email: "",
      inputRules: [
        (v) => !!v || "Pflichtfeld",
        (v) => v.length >= 3 || "Bitte geben Sie mindestens 3 Zeichen ein.",
      ],
      confirmBar: false,
      selectedItem: null,
      submitted: false,
      addedImages: [],
      ImageName: "",
      Successfulls: [],
      valid: false,
      file: null,
      clickLock: false,
      files: new FormData(),
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    gallery() {
      return this.$store.state.galleries.selected;
    },
    Gallery() {
      let item = { id: 0, title: "", description: "" };
      if (this.gallery.item != undefined) {
        item = this.gallery.item;
      }
      return item;
    },
    isLoading() {
      return this.$store.state.galleries.selected.isLoading;
    },
    uploadSuccesses() {
      return this.$store.state.images.uploaded;
    },
    uploadErrors() {
      return this.$store.state.images.failed;
    },
    isAdmin() {
      return this.getRoles().includes("Admin User");
    },
  },
  methods: {
    deleteItem() {
      if (this.selectedItem) {
        this.clickLock = true;
        this.$store.dispatch("galleries/deletePhoto", this.selectedItem).then((response) => {
          console.log(response);
          this.selectedItem = null;
          this.confirmBar = false;
          this.$root.$emit("reload_gallery_details");
        })
        this.clickLock = false;
      }
    },
    downloadGallery() {
        this.submitted = true;
        let imageIds = [];
        this.Gallery.photos.forEach(photo => {
          imageIds.push(photo.id);
        }); 
        
        console.log(imageIds);

        let dto = {
          PhotoIds: imageIds,
          EMailReceiver: ""
        };

        console.log(dto);

        this.$store.dispatch("galleries/downloadSelection", dto).then((response) => {
          console.log(response);
          if (response.path) {
            console.info(response.path);
            let url = process.env.VUE_APP_API_ROUTE + "/" +  response.path;
            this.submitted = false;
            window.open(url);
          }
        })
    },
    downloadSelection() {
        this.submitted = true;
        let dto = {
          PhotoIds: this.addedImages,
          EMailReceiver: ""
        };

        this.$store.dispatch("galleries/downloadSelection", dto).then((response) => {
          console.log(response);
          if (response.path) {
            console.info(response.path);
            let url = process.env.VUE_APP_API_ROUTE + "/" +  response.path;
            this.submitted = false;
            window.open(url);
          }
        })
    },
    showDialog() {
      this.dialogVisible = true;
    },
    isClicked(imageId) {
      return this.addedImages.includes(imageId);
    },
    handleImage(imageId) {
      if (this.addedImages.includes(imageId)) {
        var newArray = this.addedImages.filter(function (f) {
          return f !== imageId;
        });
        this.addedImages = newArray;
      } else {
        this.addedImages.push(imageId);
      }
      console.log(this.addedImages);
    },
    openImage(image) {
      if (this.clickLock == false) {
          window.open(this.parseUrl(image));
      }
    },
    hasRole(roleName) {
      return this.getRoles().includes(roleName);
    },
    getRoles() {
      return this.$keycloak.realmAccess.roles;
    },
    parseUrl(path) {
      let basePath = process.env.VUE_APP_API_ROUTE;
      return basePath + "/" + path;
    },
    newImage() {
      this.submitted = true;

      this.$store.dispatch("images/clear");

      for (let f of this.file) {
        this.$root.$emit(
          "show_snackbar",
          "Bitte warten. Bild wird hochgeladen: " + f.name
        );
        let formData = new FormData();
        formData.append("UploadImage", f);
        formData.append("GalleryId", parseInt(this.id));

        this.$store.dispatch("images/newImage", formData);
      }

      this.resetData();
      this.$root.$emit("reload_gallery_details");
      this.submitted = false;
    },
    resetData() {
      this.submitted = false;
      this.ImageName = "";
      this.file = null;
    },
    async getData() {
      if (this.id) {
        await this.$store.dispatch("galleries/getSingle", this.id);
      }
      else {
        console.log("NOPE!");
      }
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    Save() {
      console.log("Bibbedi Buu");
    },
  },
};
</script>

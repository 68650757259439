<template>
  <div>
    <v-list dense nav>
      <router-link role="menuitem" to="/profile" tabindex="-1">
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              <v-list-item-avatar color="red">
                <span class="white--text headline">{{ initials }}</span>
              </v-list-item-avatar>
              {{ username }}
            </v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <!--<a :href="getUserInfoURL()">
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title"></v-list-item-title>
            <v-list-item-subtitle>Mein Profil verwalten</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </a>-->
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <!--<v-list-group no-action
      >
        <template v-slot:activator>
          
          <v-list-item-content>
            <v-list-item-title>Bildergalerien</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in galleries.items"
          :key="child.id"
        >
          <v-list-item-content>
            <v-btn @click="navigateGallery(child.id)" link><v-list-item-title v-text="child.title"></v-list-item-title></v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>-->

      <v-list-item link v-if="isAdmin">
        <v-list-item-icon>
          <v-icon>mdi-image-area</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <router-link to="/">
            Bildergalerien
          </router-link></v-list-item-title
        >
      </v-list-item>

      <v-list-item link v-if="isAdmin">
        <v-list-item-icon>
          <v-icon>mdi-account-voice</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <router-link to="/greetings">
            Hochzeitsgrüße
          </router-link></v-list-item-title
        >
      </v-list-item>

      <v-divider v-if="isAdmin"></v-divider>

      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <a href="mailto:skempers@gmail.com" target="_blank">
            Hilfe erhalten
          </a></v-list-item-title
        >
      </v-list-item>

      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-account-lock-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          ><a :href="getLogoutURL()"> Abmelden </a></v-list-item-title
        >
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item link dense>
        <v-list-item-content>
          <v-list-item-title>
            <v-icon>mdi-arrow-left</v-icon>
            <a href="https://hochzeit.kempers.club">Zurück zur Hauptseite</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { getUsername, getInitials } from "../../helpers";

export default {
  created() {
    this.getData();
  },
  data() {
    return {};
  },
  computed: {
    galleries() {
      return this.$store.state.galleries.all;
    },
    isLoading() {
      return this.$store.state.galleries.all.loading;
    },
    isAdmin() {
      return this.getRoles().includes("Admin User");
    },
    username() {
      try {
        return getUsername();
      } catch {
        return "";
      }
    },
    initials() {
      try {
        return getInitials();
      } catch {
        return "";
      }
    },
  },
  methods: {
    navigateGallery(id) {
      this.$router.push("/gallery/" + id);
       this.$root.$emit(
          "reload_galleries_data"
        );
    },
    hasRole(roleName) {
      if (!roleName || roleName == "") {
        return true;
      }
      return this.getRoles().includes(roleName);
    },
    getRoles() {
      return this.$keycloak.realmAccess.roles;
    },
    async getData() {
      await this.$store.dispatch("galleries/getAll");
    },
    getLogoutURL() {
      return process.env.VUE_APP_KEYCLOAK_ENDSESSION_ROUTE;
    },
    getUserInfoURL() {
      return process.env.VUE_APP_KEYCLOAK_USERACCOUNT_ROUTE;
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: #3b3b3b;
}
.theme--light.v-list-item--active {
  background-color: #38633a !important;
}

.v-list-item--active.v-list-item--link.theme--light > div.v-list-item__title {
  color: white !important;
}

div.v-list-item__title > a {
  color: black;
}

div.v-list-group.v-list-group--active.primary--text
  > div.v-list-group__items
  > div
  > div.v-list-item__title
  > a {
  color: #38633a !important;
  padding-left: 7%;
}
</style>

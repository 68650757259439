import {
    imageService
} from '../services';

export const images = {
    namespaced: true,
    state: {
        all: {},
        selected_image: {},
        new: {},
        delete: {},
        update: {},
        uploaded: [],
        failed: []
    },
    actions: {
        reset({
            commit
        }) {
            commit('imagesReset');
        },

        clearInfo({
            commit
        }) {
            commit('infoReset');
        },

        getAll({
            commit
        }, id) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                imageService.getAll(id)
                    .then(
                        images => {
                            commit('getAllSuccess', images)
                            resolve(images)
                        },
                        error => {
                            commit('getAllFailure', error)
                            reject(error)
                        }
                    );
            });
        },
        getImage({
            commit
        }, id) {
            commit('getImageRequest');

            imageService.getImage(id)
                .then(
                    image => commit('getImageSuccess', image),
                    error => commit('getImageFailure', error)
                );
        },
        newImage({
            commit
        }, image) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                imageService.newImage(image)
                    .then(
                        payload => {
                            commit('uploadedSuccess', image);
                            resolve(payload)
                        },
                        error => {
                            commit('uploadedFailed', image);
                            reject(error)
                        }
                    );
            })

        },

        newRendering({
            commit
        }, payload) {
            commit('newRequest');
            return new Promise((resolve, reject) => {
                imageService.newRendering(payload)
                    .then(
                        payload => {
                            commit('newSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('newFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        updateRendering({
            commit
        }, payload) {
            commit('updateRequest');
            return new Promise((resolve, reject) => {
                imageService.updateRendering(payload)
                    .then(
                        payload => {
                            commit('updateSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('updateFailure', error);
                            reject(error)
                        }
                    );
            })

        },


        deleteImage({
            commit
        }, payload) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                imageService.deleteImage(payload)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },

        deleteRendering({
            commit
        }, payload) {
            commit('deleteRequest');
            return new Promise((resolve, reject) => {
                imageService.deleteRendering(payload)
                    .then(
                        payload => {
                            commit('deleteSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('deleteFailure', error);
                            reject(error)
                        }
                    );
            })

        },
    },
    mutations: {

        deleteRequest(state) {
            state.delete = {
                loading: true
            };
        },
        deleteSuccess(state, payload) {
            state.delete = {
                item: payload,
                loading: false
            };
        },
        deleteFailure(state, error) {
            state.delete = {
                error,
                loading: false
            };
        },


        uploadedSuccess(state, payload) {
            let info = "";
            for (var pair of payload.entries()) {
                if (pair[0] == "UploadImage") {
                    info = pair[1].name;
                    break;
                }
              }
            state.uploaded.push(info);
        },
        uploadedFailed(state, error) {
            let info = "";
            for (var pair of error.entries()) {
                if (pair[0] == "UploadImage") {
                    info = pair[1].name;
                    break;
                }
              }

            state.failed.push(info);
        },


        updateRequest(state) {
            state.update = {
                loading: true
            }; 
        },
        updateSuccess(state, payload) {
            state.update = {
                item: payload,
                loading: false
            }; 
        },
        updateFailure(state, error) {
            state.update = {
                error,
                loading: false
            }; 
        },


        newRequest(state) {
            state.new = {
                loading: true
            }; 
        },
        newSuccess(state, payload) {
            state.new = {
                item: payload,
                loading: false
            }; 
        },
        newFailure(state, error) {
            state.new = {
                error,
                loading: false
            };
        },


        imagesReset(state) {
            state.all = {
                items: null
            };
            state.all = {
                loading: false
            };
        },

        infoReset(state) {
            state.failed = {};
            state.uploaded = {};
        },

        getAllRequest(state) {
            state.all = {
                loading: true
            };
        },
        getAllSuccess(state, images) {
            state.all = {
                items: images
            };
        },
        getAllFailure(state, error) {
            state.all = {
                error
            };
        },
        getImageRequest(state) {
            state.selected_image = {
                loading: true
            };
        },
        getImageSuccess(state, image) {
            state.selected_image = {
                item: image,
                loading: false
            };
        },
        getImageFailure(state, error) {
            state.selected_image = {
                error,
                loading: false
            };
        },
    }
}
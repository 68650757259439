<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
         <router-link to="/">Zurück</router-link>
         </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Eure Hochzeitsgrüße</h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="viewData" fluid>
       <v-data-table dense
      :headers="headers"
      :items="viewData.items"
      item-key="id"
      :loading="isLoading"
      :items-per-page="25"
      loading-text="Wird geladen"
      :search="search"
      no-data-text="keine Daten vorhanden"
      no-results-text="keine Suchergebnisse"
      locale="de-de"
      class="elevation-1 color_table"
      :footer-props="{
        itemsPerPageAllText: 'Alle',
        itemsPerPageText: 'Einträge pro Seite',
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Suchen"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.isPublished="{ item }">
        <v-chip v-if="item.isPublished" color="green">Ja</v-chip>
        <v-chip v-else color="red">Nein</v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="switchState(item)">
          mdi-arrow-right-bold-box
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" tile @click="getData"> Neu laden </v-btn>
      </template>
    </v-data-table>

    
    </v-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
    created() {
        this.getData();
    },
    mounted() {
        this.$root.$on("reload_greetings_data", () => {
            this.getData();
        });
    },
    data() {
        return {
            selectedTime: this.time,
            confirmBar: false,
            headers: [
                { text: "Von", value: "from" },
                { text: "Nachricht", value: "text" },
                { text: "Veröffentlicht?", value: "isPublished" },
                { text: "Freigeben", value: "actions", sortable: false },
            ],
            search: "",
        };
    },
    computed: {
        selectedItem: {
            get() {
                return this.currentItem;
            },
            set(value) {
                this.currentItem = value;
            }
        },
        viewData() {
            return this.$store.state.greetings.viewData;
        },
        isLoading() {
            return this.$store.state.greetings.viewData.loading;
        },
    },
    methods: {
        parseDate(date) {
            if (date) {
                return moment.utc(date).format("DD.MM.yyyy hh:mm");
            }
            return "";
        },
        async getData() {
            await this.$store.dispatch("greetings/getData");
        },
        switchState(item) {
          console.log(item);
           if (item.isPublished) {
            this.$store.dispatch("greetings/decline", item).then((response) =>  {
              console.log(response)
              this.$root.$emit("reload_greetings_data");
            });
           }
           else {
            this.$store.dispatch("greetings/accept", item).then((response) =>  {
              console.log(response)
              this.$root.$emit("reload_greetings_data");
            });
           }
        },
    },
};
</script>


<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Unsere Bilder</h3>
          <span
            >In den Galerien habt ihr die Möglichkeit, selbst Bilder hoch- und herunterzuladen.</span
          >
        </v-col>
      </v-row>
    </v-container>

    <NewGallery v-if="isAdmin"></NewGallery>

    <v-container v-if="galleries" fluid>
      <template v-if="galleries.items">
        <v-row>
          <v-col
            xs="12"
            sm="6"
            md="4"
            lg="3"
            v-for="list in galleries.items"
            :key="list.id"
          >
            <v-card max-width="390" v-if="hasRole(list.accessRoleName)"  class="hoverImage">
              <template v-if="list.previewImage">
                <v-img @click="navigateGallery(list.id)"
                  class="white--text align-end"
                  :lazy-src="list.previewImage"
                  :src="list.previewImage"
                  max-width="380"
                >
                  <v-card-actions style="right: 0; top: 0; position: absolute;" v-if="isAdmin">
                    <v-btn @click="showConfirmBar(list)" color="error"
                      ><i class="fa fa-trash-alt"></i
                    ></v-btn>
                  </v-card-actions>
                  <v-card-title>{{ list.title }}</v-card-title>
                  <v-card-subtitle class="pb-0"
                    >{{ list.description }}
                  </v-card-subtitle>
                </v-img>
              </template>
              <template v-else>
                <v-img @click="navigateGallery(list.id)"
                  class="white--text align-end"
                  src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  max-width="380"
                >
                <v-card-actions style="right: 0; top: 0; position: absolute;">
                    <v-btn @click="showConfirmBar(list)" color="error"
                      ><i class="fa fa-trash-alt"></i
                    ></v-btn>
                  </v-card-actions>
                  <v-card-title>{{ list.title }}</v-card-title>
                  <v-card-subtitle class="pb-0"
                    >{{ list.description }}
                  </v-card-subtitle>
                </v-img>
              </template>

              <v-card-subtitle class="pb-0"
                >Bilder: {{ list.photos.length }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <div class="text-center ma-2" v-if="isAdmin">
      <v-snackbar v-model="confirmBar" bottom :timeout="10000">
        Möchten Sie die Galerie wirklich löschen?
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="closeConfirmBar()">
            Abbrechen
          </v-btn>

          <v-btn color="accent" text v-bind="attrs" @click="deleteItem()">
            Bestätigen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import NewGallery from "../gallery/NewGallery.vue";
import moment from "moment";

export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_gallery_data", () => {
      this.getData();
    });
  },
  data() {
    return {
      text: [],
      selectedItem: null,
      confirmBar: false,
    };
  },
  computed: {
    galleries() {
      return this.$store.state.galleries.all;
    },
    isLoading() {
      return this.$store.state.galleries.all.loading;
    },
    isAdmin() {
      return (this.getRoles().includes("Admin User"));
    }
  },
  methods: {
    navigateGallery(id) {
      if (this.selectedItem) {
        return;
      }
      this.$router.push("/gallery/" + id);
    },
    hasRole(roleName) {
      if (!roleName || roleName == "") {
        return true;
      }
      return (this.getRoles().includes(roleName));
    },
    getRoles() {
        return this.$keycloak.realmAccess.roles;
    },
    parseDate(date) {
      return moment.utc(date).format("DD.MM.yyyy hh:mm");
    },
    async getData() {
      await this.$store.dispatch("galleries/getAll");
    },
    closeConfirmBar() {
      this.confirmBar = false;
      this.selectedItem = null;
    },
    showConfirmBar(item) {
      this.selectedItem = item;
      this.confirmBar = true;
    },
    deleteItem() {
      if (this.selectedItem == null) return;

      if (this.isAdmin) {
        this.$store.dispatch("galleries/delete", this.selectedItem).then(() => {
          this.$root.$emit("reload_gallery_data");
          this.closeConfirmBar();
        });
      }
    },
    Reset() {
      this.text = [];
    },
  },
  components: {
    NewGallery,
  },
};
</script>

<style scoped>
.successfull {
  background-color: rgb(167, 218, 167);
  list-style: none;
  color: black;
  margin-left: -25px;
  padding: 6px;
  border: thin lightgray solid;
}
.failed {
  background-color: rgb(252, 136, 136);
  color: white;
  list-style: none;
  margin-left: -25px;
  padding: 6px;
  border: thin lightgray solid;
}
.hoverImage:hover {
  box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
}
</style>

<template>
  <div>
    <v-container fluid class="topfixure">
      <v-row>
        <v-app-bar
          color="grey lighten-4"
          light
          class="pt-1"
          dense
          prominent
          style="height: 90px;"
        >
          <v-container fluid class="header">
            <v-row>
              <v-col cols="3">
                <v-app-bar-nav-icon
                  color="primary"
                  @click.stop="drawer = !drawer"
                ></v-app-bar-nav-icon>
              </v-col>
              <v-col cols="6" class="logo-block">
                <center>
                 <a href="https://hochzeit.kempers.club"><h1 style="font-family: 'Tangerine', cursive;">Anna-Lena und Sascha</h1></a>
                </center>
              </v-col>
              <v-col cols="3" class="search-box search-block1">
                <a rel="nofollow" href="mailto:skempers@gmail.com"
                  >Kontakt</a
                >
              </v-col></v-row
            ></v-container
          >
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" absolute bottom temporary>
          <Sidebar></Sidebar>
        </v-navigation-drawer>
      </v-row>
      <v-row>
        <v-app-bar
          color="primary"
          dark
          dense
          class="pt-1 mb-2 nomp"
        >
        <v-container fluid>
          <v-row>
          <h1 class="ml-2 pb-2 apptitlebar">Bilder</h1>
          </v-row>

        </v-container>
          
        </v-app-bar>
      </v-row>
      <v-row>
        <v-col>
          <v-container fluid class="mx-auto">
          <v-row>
            <v-col>
              <router-view></router-view>
            </v-col>
            
          </v-row>
        </v-container>
        </v-col>
        
      </v-row>
      <Footer></Footer>
    </v-container>
  </div>
</template>

<script>
import Sidebar from "../components/shared/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  //logic
  computed: {

  },
};
</script>


<style>
.theme--light {
  background: white !important;
}

.logo-block a {
  font-size: medium !important;
  margin-top: 0px;
}

.topfixure {
  top: 0 !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.nomp {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>


<style scoped>
.apptitlebar {
  font-family: Oswald, sans-serif;
  font-weight: 400;
  padding-top: 4px;
}
ul,
li {
  display: inline;
}
li {
  margin-left: 12px;
}

a,
li,
span,
p,
i,
b {
  font-size: medium !important;
  color: #b0b0b0 !important;
}

.logo-block {
  left: auto;
  right: auto;
}
.nopadding {
  padding: 0px;
}
</style>

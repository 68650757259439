<template>
  <v-container fluid>
    <v-row>
      <v-col class="mx-auto">
        <v-dialog transition="dialog-bottom-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile color="primary" v-bind="attrs" v-on="on"
              >Neue Galerie anlegen</v-btn
            >
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark
                >Neue Galerie hinzufügen</v-toolbar
              >
              <v-card-text>
                <v-form v-model="valid" class="mx-auto">
                  <v-text-field
                    v-model="Gallery.Title"
                    :rules="inputRules"
                    label="Titel"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="Gallery.Description"
                    label="Beschreibung"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="Gallery.AccessRoleName"
                    label="Rolle mit Zugang"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="Gallery.PreviewImage"
                    label="Bild aus Webadresse"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Schließen</v-btn>
                <v-btn tile color="primary" @click="Save"
                  >Anlegen</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      dialog: false,
      Gallery: {
        Title: "",
        Description: "",
        PreviewImage: "",
        AccessRoleName: null,
      },
      inputRules: [
        (v) => !!v || "Pflichtfeld",
        (v) => v.length >= 3 || "Bitte geben Sie mindestens 3 Zeichen ein.",
      ],
    };
  },
  methods: {
    Reset() {
      this.Gallery.Name = "";
      this.Gallery.Description = "";
      this.Gallery.PreviewImage = "";
      this.Gallery.AccessRoleName = null;
      this.dialog = false;
    },
    Save() {
      let dto = {
        Title: this.Gallery.Title,
        Description: this.Gallery.Description,
        PreviewImage: this.Gallery.PreviewImage,
        AccessRoleName: this.Gallery.AccessRoleName,
      };
      this.$store.dispatch("galleries/create", dto).then(() => {
        this.$root.$emit("reload_gallery_data");
        this.Reset();
      });
    },
  },
};
</script>

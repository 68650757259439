import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
        light: {
            primary: '#6c757d',
            secondary: '#6c757d',
            info: '#38633A',
            accent: '#778A7D',
            error: '#E63338',
            success: '#23cf5f',
        }
    }
}
});

import Vue from 'vue'
import App from './App.vue'
//import * as Keycloak from 'keycloak-js'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import authentication from "./plugins/authentication";
Vue.use(authentication);

import VueJwtDecode from 'vue-jwt-decode'

Vue.use(VueJwtDecode)
Vue.use(vuetify)

Vue.$keycloak
  .init({ checkLoginIframe: false })
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  

    
  })


import { greetingsService } from '../services';

export const greetings = {
    namespaced: true,
    state: {
        viewData: {},
        selected: {},
        default: {},
        error: {}
    },
    actions: {
        getData({ commit }) {
            commit('getAllReset');
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                greetingsService.getData()
                .then(
                    payload => {
                        commit('getAllSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getAllFailure', error)
                        reject(error)
                    } 
                )
            });
        },

        accept({ commit }, id) {
            commit('getRequest');

            return new Promise((resolve, reject) => {
                greetingsService.accept(id)
                .then(
                    payload => {
                        commit('getSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    } 
                )
            });
        },

        decline({ commit }, id) {
            commit('getRequest');

            return new Promise((resolve, reject) => {
                greetingsService.decline(id)
                .then(
                    payload => {
                        commit('getSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    } 
                )
            });
        },

        delete({
            commit
        }, dto) {
            commit('defaultRequest');
            return new Promise((resolve, reject) => {
                greetingsService.deleteExisting(dto)
                    .then(
                        payload => {
                            commit('defaultRequest', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultRequest', error);
                            reject(error)
                        }
                    );
            })
        },
    },
    mutations: {

        defaultRequest(state) {
            state.default = {
                loading: true
            }; 
        },
        defaultSuccess(state, payload) {
            state.default = {
                item: payload,
                loading: false
            }; 
        },
        defaultFailure(state, error) {
            state.default = {
                error,
                loading: false
            }; 
        },

        getRequest(state) {
            state.selected = { loading: true };
        },
        getSuccess(state, payload) {
            state.selected = { item: payload };
        },
        getFailure(state, error) {
            state.selected = { error };
        },
        getAllRequest(state) {
            state.viewData = { loading: true };
        },
        getAllReset(state) {
            state.viewData = { items: null };
        },
        getAllSuccess(state, payload) {
            state.viewData = { items: payload };
        },
        getAllFailure(state, error) {
            state.viewData = { error };
        },

        getDetailsRequest(state) {
            state.details = { loading: true };
        },
        getDetailsSuccess(state, payload) {
            state.details = { items: payload };
        },
        getDetailsFailure(state, error) {
            state.details = { error };
        },

        getTotalInfoRequest(state) {
            state.totalInfo = { loading: true };
        },
        getTotalInfoSuccess(state, payload) {
            state.totalInfo = { items: payload };
        },
        getTotalInfoFailure(state, error) {
            state.totalInfo = { error };
        },
        
        getChartDataRequest(state) {
            state.chartData = { loading: true };
        },
        getChartDataSuccess(state, payload) {
            state.chartData = { items: payload };
        },
        getChartDataFailure(state, error) {
            state.chartData = { error };
        }
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Zurück")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Eure Hochzeitsgrüße")])])],1)],1),(_vm.viewData)?_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 color_table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.viewData.items,"item-key":"id","loading":_vm.isLoading,"items-per-page":25,"loading-text":"Wird geladen","search":_vm.search,"no-data-text":"keine Daten vorhanden","no-results-text":"keine Suchergebnisse","locale":"de-de","footer-props":{
      itemsPerPageAllText: 'Alle',
      itemsPerPageText: 'Einträge pro Seite',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suchen"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.isPublished",fn:function(ref){
    var item = ref.item;
return [(item.isPublished)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v("Ja")]):_c('v-chip',{attrs:{"color":"red"}},[_vm._v("Nein")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.switchState(item)}}},[_vm._v(" mdi-arrow-right-bold-box ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","tile":""},on:{"click":_vm.getData}},[_vm._v(" Neu laden ")])]},proxy:true}],null,false,2974242575)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
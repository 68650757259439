import {
    jwt_decode
} from '../helpers';

export function authHeader() {
    // return authorization header with jwt token
    let token = localStorage.getItem('vue-token');
    return { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'  };
}

export function getUsername() {
    try {
        let token = localStorage.getItem('vue-token');
        let decoded = jwt_decode(token);
        return decoded.username;
    }
    catch {
        return "";
    }

}

export function getEmail() {
    try {
        let token = localStorage.getItem('vue-token');
        let decoded = jwt_decode(token);
        return decoded.email;
    }
    catch {
        return "";
    }

}

export function getFirstName() {
    try {
        let token = localStorage.getItem('vue-token');
        let decoded = jwt_decode(token);
        return decoded.firstname;
    }
    catch {
        return "";
    }
}

export function getLastName() {
    try {
        let token = localStorage.getItem('vue-token');
        let decoded = jwt_decode(token);
        return decoded.lastname;
    }
    catch {
        return "";
    }

}

export function getInitials() {
    try {
        let token = localStorage.getItem('vue-token');
        let decoded = jwt_decode(token);
    
        let firstLetter = decoded.firstname.charAt(0);
        let secondLetter = decoded.lastname.charAt(0);
    
        return (firstLetter + secondLetter).toUpperCase();
    }
    catch {
        return "";
    }
}

export function authHeaderMultiform() {
    // return authorization header with jwt token
    let token = localStorage.getItem('vue-token');
    return { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' };
}

export function authHeaderUndefined() {
    // return authorization header with jwt token
    let token = localStorage.getItem('vue-token');
    return { 'Authorization': 'Bearer ' + token };
}


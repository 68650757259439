import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { galleries } from './gallery.module';
import { images } from './images.module';
import { greetings } from './greetings.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        alert,
        galleries,
        images,
        greetings
    }
});